import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Box, Stack, xcss } from '@atlaskit/primitives';
import Image from '@atlaskit/image';
import Skeleton from '@atlaskit/skeleton';

import errorWarning from './assets/errorWarning.svg';
import errorSearchLight from './assets/errorSearchLight.svg';
import errorSearchDark from './assets/errorSearchDark.svg';

type ContentMenuErrorStateProps = {
	errorTitle: string;
};

type ContentMenuEmptyStateProps = {
	image: string;
	title: string;
	message: string;
};

type ImageTextStackProps = {
	image: string;
	message: string;
	title?: string;
	darkImage?: string;
};

const ImageWithText = ({ image, message, title, darkImage }: ImageTextStackProps) => {
	return (
		<Stack alignInline="center" alignBlock="center" grow="fill">
			<Image src={image} srcDark={darkImage || ''} alt="" width="160px" height="160px" />
			<Stack alignInline="center" space="space.075">
				{title && <Box xcss={headerStyles}>{title}</Box>}
				<Box xcss={textStyles}>{message}</Box>
			</Stack>
		</Stack>
	);
};

export const ContentMenuErrorState = ({ errorTitle }: ContentMenuErrorStateProps) => {
	const intl = useIntl();
	return (
		<ImageWithText
			image={errorWarning}
			title={errorTitle}
			message={intl.formatMessage(i18n.errorMessageSubtitle)}
		/>
	);
};

export const ContentMenuNoResultsState = () => {
	const intl = useIntl();
	return (
		<ImageWithText
			image={errorSearchLight}
			message={intl.formatMessage(i18n.noResultsMessageSubtitle)}
			darkImage={errorSearchDark}
		/>
	);
};

export const ContentMenuEmptyState = ({ image, title, message }: ContentMenuEmptyStateProps) => {
	return <ImageWithText image={image} title={title} message={message} />;
};

export const ContentMenuLoadingState = () => {
	return (
		<Box paddingBlock="space.300">
			<Stack
				space="space.300"
				alignBlock="center"
				alignInline="center"
				grow="fill"
				testId="content-menu-loading"
			>
				{[...Array(10)].map((_, index) => (
					<Skeleton key={index} width="250px" height="9px" borderRadius="3px" />
				))}
			</Stack>
		</Box>
	);
};

const headerStyles = xcss({
	fontSize: 'font.heading.xsmall',
	fontWeight: '700',
	color: 'color.text.subtle',
	lineHeight: '20px',
});

const textStyles = xcss({
	fontSize: 'font.body.small',
	lineHeight: '16px',
	color: 'color.text.subtle',
	textAlign: 'center',
	fontWeight: '400',
});

const i18n = defineMessages({
	errorMessageSubtitle: {
		id: 'side-navigation.content-menu.error-message.subtitle',
		defaultMessage: 'Refresh the page and try again.',
		description: 'Subtitle for the error message in the content menu',
	},
	noResultsMessageSubtitle: {
		id: 'side-navigation.content-menu.no-results-message.subtitle',
		defaultMessage: 'We couldn’t find what you’re looking for.',
		description: 'Subtitle for the no results message in the content menu',
	},
});
